@import url(https://use.typekit.net/knz7vmu.css);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
@import url(https://use.typekit.net/knz7vmu.css);
body {
    margin: 0;
    font-family: "Europa";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.Appy {
    position: relative;

    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}

a {
    color: none;
}

h2 {
    font-weight: 700;
    font-size: 1.25rem;
}

h3 {
    font-weight: 700;
    font-size: 1rem;
}

button {
    cursor: pointer;
}

iframe {
    position: absolute;
    width: 100%;
    height: 1000px;
    z-index: 0;

    /* @media (max-width: 1086px) {
        right: 0px;
    } */
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr min(60ch, calc(100% - 64px)) 1fr;
    grid-column-gap: 32px;
}

.wrapper > * {
    grid-column: 2;
}

.full-bleed {
    width: 100%;
    grid-column: 1 / 4;
}

.half-bleed {
    width: 100%;
    grid-column: 2 / 3;
    border-radius: 8px;
}

.icon-scroll,
.icon-scroll:before {
}

.icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    top: 50%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
}

.icon-scroll:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
}

@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(46px);
    }
}
#canvas3d {
    @media (max-width: 1086px) {
        width: 100vw !important;
    }
}

